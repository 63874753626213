@import '../../styles/index';

.wrapper {
  margin-bottom: 25px;
  position: relative;
  width: 100%;

  @include media-from(sm) {
    margin-bottom: 5.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include media-from(lg) {
    height: 480px;
    margin-bottom: 3.75rem;

    &:nth-child(even) {
      margin-bottom: 6.5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.base {
  display: block;
  max-width: 74rem;
  position: relative;
  width: 100%;
  @include media-from(sm) {
    display: flex;
    flex-direction: column;
  }
  @include media-from(lg) {
    align-items: center;
    flex-direction: initial;
    height: 28rem;
    position: absolute;
  }
}

.image {
  border-top-left-radius: $border-radius-default;
  border-top-right-radius: $border-radius-default;
  height: 100%;
  overflow: hidden;
  width: 100%;

  @include media-from(sm) {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 3.4375rem), 0% 100%);
  }

  @include media-from(lg) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    // height: 28rem;
  }
}

.image-wrapper {
  border-top-left-radius: $border-radius-default;
  border-top-right-radius: $border-radius-default;
  // height: 320px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;

  @include media-from(lg) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: absolute;
    width: 625px;
    z-index: $index-zero;
  }
}

.box {
  background-color: $color-white--100;
  box-shadow:
    0 0 2px 0 rgba(0, 0, 0, 0.1),
    0 0 27px 0 rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  padding: 1rem;
  width: 100%;

  @include media-from(sm) {
    border-radius: $border-radius-default;
    max-width: 40rem;
    padding: 1.625rem;
    width: auto;
  }

  &.box-with-link {
    @include media-from(lg) {
      padding: 1.625rem 1.625rem 0.625rem;
    }
  }
}

.content-wrapper {
  @include media-from(lg) {
    align-items: center;
    display: flex;
    height: 100%;
    max-width: 40rem;
    position: absolute;
    width: 100%;
  }
}

.badge {
  position: absolute;
  z-index: $index-showcase-badge;
}

.badge-desktop {
  display: none;
  @include media-from(sm) {
    display: block;
    right: calc(6rem - #{$badge-sm});
    top: calc((1.625rem + 6px) - #{$badge-sm});
  }
}

.badge-mobile {
  bottom: 0;
  display: block;
  right: 0;
  @include media-from(sm) {
    display: none;
  }
}

.title {
  font-size: $font-size-22;
  font-weight: $font-weight-bold;
  line-height: 1.3;
  margin: 0 0 0.625rem;

  @include media-from(sm) {
    font-size: $font-size-24;
  }
}

.description {
  font-size: $font-size-18;
  font-weight: $font-weight-light;
  line-height: 1.6;
  margin: 0;

  @include media-from(sm) {
    font-size: $font-size-20;
  }
}

.badge-text-element {
  @include media-from(sm) {
    margin: 0 auto;
    margin-top: -7.5rem;
    max-width: 40rem;
    position: relative;
    width: 100%;
    z-index: $index-showcase-badge-text;
  }

  @include media-from(lg) {
    margin: 0;
    position: absolute;
    width: auto;
  }
}

.left {
  left: 0;

  .content-wrapper {
    right: 0;
  }

  .image-wrapper {
    left: 0;
  }

  .badge-text-element {
    @include media-from(sm) {
      bottom: 0;
    }
    @include media-from(lg) {
      bottom: initial;
      left: 0;
    }
  }
}

.right {
  right: 0;

  .content-wrapper {
    left: 0;
  }

  .image-wrapper {
    right: 0;
  }

  .badge-text-element {
    @include media-from(sm) {
      bottom: 0;
    }
    @include media-from(lg) {
      bottom: initial;
      right: 0;
    }
  }
}

.link {
  padding: 1rem 0 0.5rem;

  @include media-from(sm) {
    padding: 1.125rem 0;
  }
}
